import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Link from "@material-ui/core/Link"

interface FooterItem {
  displayText: React.ReactNode
  url?: string
}

interface FooterLink {
  title: string
  items: FooterItem[]
}

const Footer = () => {
  const footerLinks: FooterLink[] = [
    {
      title: "Information",
      items: [
        { displayText: "Punzalan Law" },
        {
          displayText: "Unit 210B - 10 Fort St",
        },
        { displayText: "Winnipeg, MB Canada R3C 1C4" },
      ],
    },
    {
      title: "Hours",
      items: [
        { displayText: "Monday - Friday" },
        { displayText: "08:00 AM - 04:30 PM" },
      ],
    },
    {
      title: "Service Area",
      items: [{ displayText: "Winnipeg and surrounding areas" }],
    },
  ]

  return (
    <React.Fragment>
      <Box
        className="footer"
        style={{
          backgroundColor: "#163D75",
        }}
      >
        <Grid
          container
          style={{
            margin: `0 auto`,
            minHeight: 150,
            color: "#fff",
            backgroundColor: "#163D75",
          }}
        >
          <Hidden xsDown>
            {footerLinks.map(footerMenu => (
              <Grid
                item
                xs={12}
                sm={4}
                container
                direction="column"
                justify="flex-start"
                key={footerMenu.title}
                style={{ padding: 10 }}
              >
                <Typography variant="h6" color="inherit">
                  {footerMenu.title}
                </Typography>
                {footerMenu.items.map(link => {
                  if (
                    typeof link.displayText === "string" ||
                    link.displayText instanceof String
                  ) {
                    return link.url ? (
                      <Link
                        variant="subtitle2"
                        key={link.displayText as string}
                      >
                        {link.displayText}
                      </Link>
                    ) : (
                      <Typography variant="subtitle2">
                        {link.displayText}
                      </Typography>
                    )
                  } else {
                    return link.displayText
                  }
                })}
              </Grid>
            ))}
          </Hidden>
        </Grid>
      </Box>
      <Box className="attribution">
        <Grid
          container
          style={{
            margin: `0 auto`,
            color: "#FFF",
            backgroundColor: "#163D75",
          }}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid
            xs={12}
            item
            direction="column"
            justify="space-evenly"
            style={{ padding: 10 }}
          >
            <Typography variant="body2" color="inherit" align="center">
              Copyright Punzalan Law 2018 - Legal
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  )
}
export default Footer
